import { createSlice } from '@reduxjs/toolkit';
import { SpotifyToken } from '../../@types/spotify/spotify-token';

export interface SpotifyState {
  token: SpotifyToken | null;
}

export const spotifyState = createSlice({
  name: 'spotify',
  initialState: {
    token: null,
  } as SpotifyState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setToken } = spotifyState.actions;

export default spotifyState.reducer;
