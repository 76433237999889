const MockInput: React.FC<{
  handleEdit: (editing: boolean) => void;
  value: string;
  label: string;
}> = ({ handleEdit, value, label }) => {
  return (
    <div
      className="flex items-start border border-transparent"
      onClick={() => handleEdit(true)}
    >
      <div className="flex justify-between w-full items-center -translate-y-2">
        <div className="flex flex-col pb-4">
          <label className="pl-4 pb-2 text-xs text-gray-400">{label}</label>
          <span className="pl-4">{value}</span>
        </div>
        <div>
          <i className="pi pi-pencil pr-4"></i>
        </div>
      </div>
    </div>
  );
};

export default MockInput;
