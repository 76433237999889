import { useNavigate } from 'react-router';

const TitleBar: React.FC<{
  title: string;
  showBack?: boolean;
  closeModal?: () => void;
  leftButton?: any;
  rightButton?: any;
}> = ({ title, showBack = true, leftButton, rightButton, closeModal }) => {
  const navigate = useNavigate();
  const history = window.history;
  return (
    <div className="w-full flex justify-between items-center h-12">
      {leftButton ? (
        leftButton
      ) : showBack ? (
        <div
          className="w-16 ml-4 flex items-center cursor-pointer"
          onClick={() =>
            closeModal
              ? closeModal()
              : history.length > 1
              ? navigate(-1)
              : navigate('/')
          }
        >
          <i className="pi pi-chevron-left text-xl" />
          <span className="ml-1 text-sm">BACK</span>
        </div>
      ) : (
        <div className="w-16 ml-4"></div>
      )}
      <div>
        <span>{title}</span>
      </div>
      <div className="w-16 mr-4 flex justify-end">
        {rightButton && rightButton}
      </div>
    </div>
  );
};

export default TitleBar;
