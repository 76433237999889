import React, { useState } from 'react';
import ForgotPassword from '../../pages/forgot-password';
import Login from '../../pages/login';
import Register from '../../pages/register';

export enum AuthRoutes {
  Login = 'login',
  Register = 'register',
  ForgotPassword = 'forgot-password',
}

const AuthModal: React.FC<{
  onDismiss: (show: boolean) => void;
  showModal: boolean;
}> = ({ onDismiss, showModal }) => {
  const [activeRoute, setActiveRoute] = useState('login');

  const handleDismiss = () => {
    onDismiss(false);
    setTimeout(() => setActiveRoute('login'), 500);
  };

  return (
    <div className="w-screen max-w-[414px] h-screen md:max-h-[500px]">
      {activeRoute === AuthRoutes.Login && (
        <Login
          navigate={(path) => setActiveRoute(path)}
          onDismiss={() => handleDismiss()}
        />
      )}
      {activeRoute === AuthRoutes.Register && (
        <Register
          navigate={(path) => setActiveRoute(path)}
          onDismiss={() => handleDismiss()}
        />
      )}
      {activeRoute === AuthRoutes.ForgotPassword && (
        <ForgotPassword
          navigate={(path) => setActiveRoute(path)}
          onDismiss={() => handleDismiss()}
        />
      )}
    </div>
  );
};

export default AuthModal;
