/**
 * removes duplicates from an array.
 * allows for passing of an array of keys to only match certain parts
 * of an object.
 * @param arr
 * @param keys
 */
export const removeArrayDuplicates = (arr: any[], keys: string[]) => {
  return arr.reduce((acc, item) => {
    const found = acc.find((a: any) =>
      compareObjects(parseObject(a, keys), parseObject(item, keys))
    );
    return found ? acc : [...acc, item];
  }, []);
};

const compareObjects = (objOne: any, objTwo: any) => {
  // need es2019 support for flatMap
  const mapOne = Object.entries(objOne).flatMap((a) => a);
  const mapTwo = Object.entries(objTwo).flatMap((b) => b);
  if (mapOne.length !== mapTwo.length) {
    return false;
  }
  return mapOne.every((a) => mapTwo.includes(a));
};

const parseObject = (obj: any, keys: string[]) => {
  const newObj: any = {};
  for (const k of keys) {
    newObj[k] = obj[k];
  }
  return newObj;
};
