import { useEffect } from 'react';
import { supabase } from '../../@utils/supabase/supabase-client';
import { useAuth } from '../../auth/services/auth.service';
import {
  definitions,
  SongRequest,
  SpotifyTrackObject,
  VoteType,
} from '../../@types';
import { camelize, snakeize } from '../../@utils';
import { useVote } from './vote.service';
import { useDispatch } from 'react-redux';
import * as actions from '../store/dj-event-state';
interface CreateSongRequestValues {
  track: SpotifyTrackObject;
  eventId: string;
}

export const useSongRequest = () => {
  const auth = useAuth();
  const vote = useVote();
  const dispatch = useDispatch();

  const createSongRequest = async ({
    track,
    eventId,
  }: CreateSongRequestValues) => {
    try {
      const user = auth.authUser();
      if (!user) throw new Error('No user logged in');

      const savedTrack = await supabase
        .from<definitions['track']>('track')
        .upsert(
          {
            spotify_id: track.id,
            album: track.album || undefined,
            artists: track.artists,
            title: track.label,
            raw_spotify_track: JSON.stringify(track.original),
            image: track.image,
          },
          { onConflict: 'spotify_id' },
        );
      if (savedTrack.error) throw savedTrack.error;
      // check for duplicates
      const songRequest = await supabase
        .from<definitions['song_request']>('song_request')
        .select()
        .eq('track_id', savedTrack.data[0].id)
        .eq('dj_event_id', eventId);
      // if there's a duplicate, just add an upvote
      if (songRequest?.data?.length && songRequest?.data?.length > 0) {
        const request = camelize(songRequest.data[0]);
        await vote.createVote(request, VoteType.Upvote);
        return [request];
      } else {
        const { data, error } = await supabase
          .from<definitions['song_request']>('song_request')
          .upsert({
            track_id: savedTrack.data[0].id,
            requested_by_id: user.id as any,
            dj_event_id: eventId as any,
            created_by_id: user.id,
          })
          .single();
        if (error) throw error;
        const request = data ? camelize(data) : null;
        if (request) {
          await vote.createVote(request, VoteType.Upvote);
        }
        return request;
      }
    } catch (error) {
      throw error;
    }
  };

  const getSongRequestsForEvent = async (eventId: any) => {
    dispatch(actions.getSongRequests(null));
    try {
      const { data, error } = await supabase
        .from<definitions['song_request']>('song_request')
        .select(`*, track:track_id(*), votes:votes!song_request_id(*)`)
        .order('created_date')
        .eq('dj_event_id', eventId);
      if (error) throw error;
      const requests = data ? data.map((request) => camelize(request)) : [];
      console.log('sorting');
      const sorted = requests.sort((a: SongRequest, b: SongRequest) => {
        const aUpvotes = a.votes.filter(
          (vote) => vote.type === VoteType.Upvote,
        );
        const aDownvotes = a.votes.filter(
          (vote) => vote.type === VoteType.Downvote,
        );
        const bUpvotes = b.votes.filter(
          (vote) => vote.type === VoteType.Upvote,
        );
        const bDownvotes = b.votes.filter(
          (vote) => vote.type === VoteType.Downvote,
        );
        const aVotes = aUpvotes.length - aDownvotes.length;
        const bVotes = bUpvotes.length - bDownvotes.length;
        if (aVotes > bVotes) {
          return -1;
        }
        if (aVotes < bVotes) {
          return 1;
        }
        return 0;
      });
      dispatch(actions.getSongRequestsSuccess(sorted));
    } catch (error) {
      dispatch(actions.getSongRequestsFailure(error));
      throw error;
    }
  };

  const getSongRequest = async (id: number) => {
    dispatch(actions.getSingleRequest(null));
    try {
      const { data, error } = await supabase
        .from<definitions['song_request']>('song_request')
        .select(`*, track:track_id(*), votes:votes!song_request_id(*)`)
        .eq('id', id);
      if (error) throw error;
      const request = data ? camelize(data[0]) : null;
      dispatch(actions.getSingleRequestSuccess(request));
    } catch (error) {
      dispatch(actions.getSingleRequestFailure(error));
      throw error;
    }
  };

  const updateSongRequest = async (
    id: number,
    request: Partial<SongRequest>,
  ) => {
    try {
      const snakeCase = snakeize(request);
      const { data, error } = await supabase
        .from<definitions['song_request']>('song_request')
        .update(snakeCase)
        .match({ id })
        .single();
      if (error) throw error;
      const updatedRequest = data ? camelize(data) : null;
      return updatedRequest;
    } catch (error) {
      throw error;
    }
  };

  return {
    createSongRequest,
    getSongRequestsForEvent,
    getSongRequest,
    updateSongRequest,
  };
};

export const useGetSongRequestsForEventListener = (eventId: string) => {
  const songRequest = useSongRequest();
  useEffect(() => {
    songRequest.getSongRequestsForEvent(eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const requestSub = supabase
      .from<definitions['song_request']>(
        `song_request:dj_event_id=eq.${eventId}`,
      )
      .on('*', (payload) => {
        songRequest.getSongRequest(payload.new.id);
      })
      .subscribe();
    return () => {
      supabase.removeSubscription(requestSub);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
