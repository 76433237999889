import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../shared/components/form-input';
import { useAuth } from '../../services/auth.service';
import { useValidators } from '../../../shared/hooks/use-validators';
import { Button } from 'primereact/button';
import { AuthRoutes } from '../../components/auth-modal';
import AuthPageWrapper from '../../components/auth-page-wrapper';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';

const Register: React.FC<{
  navigate: (path: string) => void;
  onDismiss: () => void;
}> = ({ navigate, onDismiss }) => {
  const toast = useRef<any>(null);
  const errorToast = useRef<any>(null);
  const auth = useAuth();
  const validators = useValidators();
  const [isSubscribed, setIsSubscribed] = useState(false);

  const showSuccess = () => {
    if (toast?.current) {
      toast.current.show({
        severity: 'success',
        summary: 'Registered!',
        life: 3000,
      });
    }
  };

  const showError = (message: string) => {
    if (toast?.current) {
      errorToast.current.show({
        severity: 'error',
        summary: 'Error!',
        detail: message,
        life: 5000,
      });
    }
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleRegister = async (event: any) => {
    try {
      await auth.register(
        event.email,
        event.password,
        event.email,
        isSubscribed,
      );
      onDismiss();
      showSuccess();
    } catch (error: any) {
      console.error('error', error);
      showError(error.message || 'Unable to register user');
    }
  };

  React.useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      await trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  return (
    <AuthPageWrapper
      title="Register"
      navigate={() => navigate(AuthRoutes.Login)}
      onDismiss={() => onDismiss()}
      heading={'Create an account to make requests!'}
    >
      <form onSubmit={handleSubmit((data) => handleRegister(data))}>
        {/* <FormInput
          control={control}
          rules={{
            required: { value: true, message: 'Name is required' },
          }}
          errors={errors}
          label="Name"
          name="username"
        /> */}
        <FormInput
          control={control}
          label="Email"
          name="email"
          rules={{
            validate: async (value: string) => validators.validateEmail(value),
            required: { value: true, message: 'Email is required' },
          }}
          errors={errors}
        />
        <FormInput
          label="Password"
          name="password"
          type="password"
          rules={{
            required: { value: true, message: 'Password is required' },
            minLength: {
              value: 6,
              message: 'Password needs to be more than 6 characters',
            },
          }}
          control={control}
          errors={errors}
        />
        <div className="flex items-center">
          <Checkbox
            inputId="subscribe"
            onChange={(e) => setIsSubscribed(e.checked)}
            checked={isSubscribed}
          />
          <label htmlFor="subscribe" className="p-checkbox-label pl-2">
            Email me updates on upcoming events with my favorite DJs!
          </label>
        </div>
        <div className="flex justify-center pt-6">
          <Button onClick={handleSubmit((data) => handleRegister(data))}>
            Sign Up
          </Button>
        </div>
      </form>
      <Toast ref={toast} position="bottom-center" />
      <Toast ref={errorToast} position="bottom-center" />
    </AuthPageWrapper>
  );
};

export default Register;
