import { definitions, SongRequest, VoteType } from '../../@types';
import { supabase } from '../../@utils/supabase/supabase-client';
import { useAuth } from '../../auth/services/auth.service';

export const useVote = () => {
  const auth = useAuth();
  const upsertVote = async (
    request: SongRequest,
    type: VoteType | null,
    id?: number,
  ) => {
    try {
      const user = auth.authUser();
      const { error } = await supabase
        .from<definitions['votes']>('votes')
        .upsert(
          {
            id: id || undefined,
            song_request_id: request.id,
            type: type || (null as any),
            created_by_id: user?.id,
            dj_event_id: request.djEventId,
          },
          { onConflict: 'id', returning: 'minimal' },
        );
      if (error) throw error;
      await increaseVoteCount(request.voteCount || 0, request.id);
      return;
    } catch (error) {
      throw error;
    }
  };

  const createVote = async (request: SongRequest, type: VoteType) => {
    try {
      const user = auth.authUser();
      const createData = await supabase
        .from<definitions['votes']>('votes')
        .insert([
          {
            song_request_id: request.id,
            type,
            created_by_id: user?.id,
            dj_event_id: request.djEventId,
          },
        ]);
      if (createData.error) throw createData.error;
      await increaseVoteCount(request.voteCount || 0, request.id);
      return;
    } catch (error) {
      throw error;
    }
  };

  const deleteVote = async (voteId: number, request: SongRequest) => {
    try {
      const { error } = await supabase
        .from<definitions['votes']>('votes')
        .delete()
        .match({ id: voteId });
      if (error) throw error;
      await increaseVoteCount(request.voteCount || 0, request.id);
      return;
    } catch (error) {
      throw error;
    }
  };

  const updateVote = async (
    voteId: number,
    values: Partial<definitions['votes']>,
    request: SongRequest,
  ) => {
    try {
      const { error } = await supabase
        .from<definitions['votes']>('votes')
        .update(values)
        .match({ id: voteId })
        .single();
      if (error) throw error;
      await increaseVoteCount(request.voteCount || 0, request.id);
      return;
    } catch (error) {
      throw error;
    }
  };

  const increaseVoteCount = (count: number, id: number | string) => {
    const newCount = count + 1;
    return supabase
      .from<definitions['song_request']>('song_request')
      .update({ vote_count: newCount })
      .match({ id })
      .single();
  };

  return {
    createVote,
    deleteVote,
    updateVote,
    upsertVote,
  };
};
