import { definitions } from './api-types';
import { TrackObjectFull } from './spotify/spotify-namespace';
type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : Lowercase<S>;

type ObjectToCamel<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends Record<string, K>
    ? KeysToCamelCase<T[K]>
    : T[K];
};

export type KeysToCamelCase<T> = {
  [K in keyof T as K extends `${infer P1}_${infer P2}`
    ? CamelCase<string & K>
    : K]: T[K] extends Array<K>
    ? KeysToCamelCase<T[K][number]>[]
    : ObjectToCamel<T[K]>;
};

export type DjEvent = KeysToCamelCase<definitions['dj_event']> & {
  placeAddress?: PlaceAddress;
};

export type PlaceAddress = KeysToCamelCase<definitions['place_address']>;

export type SongRequest = KeysToCamelCase<definitions['song_request']> & {
  votes: Vote[];
  track: Track;
};

export type Track = KeysToCamelCase<definitions['track']>;

export type User = KeysToCamelCase<definitions['users']>;

export type Vote = KeysToCamelCase<definitions['votes']>;

export enum UserRoles {
  User = 'user',
  DJ = 'dj',
  Admin = 'admin',
}

export enum VoteType {
  Upvote = 'upvote',
  Downvote = 'downvote',
}

export interface SpotifyTrackObject {
  album: string | null;
  artists: string;
  label: string;
  id: string;
  original: TrackObjectFull;
  image?: string;
}

/**
 * Combining loading state and error in order to clean up reducer and make
 * displaying loading and success/error modals easier in the app.
 * Motivated by this article: https://medium.com/angular-in-depth/ngrx-how-and-where-to-handle-loading-and-error-states-of-ajax-calls-6613a14f902d
 */
export const enum LoadingState {
  INIT = 'INIT',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
}

export interface ErrorState {
  error: any;
}

export type CallState = LoadingState | ErrorState;
