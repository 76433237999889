import { BrowserRouter } from 'react-router-dom';
// import ForgotPassword from './pages/forgot-password';
// import Login from './pages/login';
// import Register from './pages/register';

const Auth = () => {
  return (
    <BrowserRouter>
      {/* <Route path={`/login`}>
          <Login />
        </Route>
        <Route path={`/register`}>
          <Register />
        </Route>
        <Route path={`/forgot-password`}>
          <ForgotPassword />
        </Route> */}
      {/* <Route exact path={path}>
        <Redirect to={`${url}/auth/login`} />
      </Route> */}
    </BrowserRouter>
  );
};

export default Auth;
