import classNames from 'classnames';
import {
  SongRequest,
  User,
  UserRoles,
  Vote,
  VoteType,
} from '../../../../@types';
import { StopIcon, PlayIcon } from '@heroicons/react/solid';
import { useSongRequest } from '../../../../shared/services/song-request.service';
import { confirmDialog } from 'primereact/confirmdialog'; // To use <ConfirmDialog> tag
import { useVote } from '../../../../shared/services/vote.service';
import { useEffect, useState } from 'react';

const SongRequestListItem: React.FC<{
  request: SongRequest;
  user: User | undefined;
  canRequest: () => boolean;
  showError: (title?: string, message?: string) => void;
  setShowAuthModal: (show: boolean) => void;
}> = ({ request, user, canRequest, showError, setShowAuthModal }) => {
  const vote = useVote();
  const [voting, setVoting] = useState<boolean>(false);
  const [upvotes, setUpvotes] = useState<Vote[]>([]);
  const [downvotes, setDownvotes] = useState<Vote[]>([]);
  const { updateSongRequest } = useSongRequest();

  useEffect(() => {
    const up = request.votes.filter((vote) => vote.type === VoteType.Upvote);
    setUpvotes(up);
    const down = request.votes.filter(
      (vote) => vote.type === VoteType.Downvote,
    );
    setDownvotes(down);
  }, [request]);

  //todo change this when we have recursive camelize arrays set up
  const userUpvoted = !!upvotes.find(
    (vote: any) => vote.created_by_id === user?.id,
  );
  const userDownvoted = !!downvotes.find(
    (vote: any) => vote.created_by_id === user?.id,
  );

  const handlePlayed = () => {
    if (user?.role === UserRoles.DJ) {
      if (!request.played) {
        confirmDialog({
          message: 'Mark song as played?',
          header: 'Song Played',
          accept: () => updateSongRequest(request.id, { played: true }),
        });
      }
    }
  };

  // votes
  const handleVote = async (type: VoteType | null) => {
    try {
      if (!canRequest()) {
        showError(
          'Unable to vote',
          'You can only vote on songs when the event is live',
        );
        return;
      }
      setVoting(true);
      if (user) {
        const currentVote = request.votes.find(
          (vote: any) => vote.created_by_id === user.id,
        );
        immediatelyVoteUpdate(type, currentVote);
        if (currentVote && currentVote.type === type) {
          type = null;
        }
        await vote.upsertVote(request, type, currentVote?.id);
      } else {
        setShowAuthModal(true);
      }
    } catch (error) {
      console.error('Unable to vote');
      showError('Voting failed', 'Unable to cast your vote. Please try again.');
    } finally {
      setVoting(false);
    }
  };

  // immediately update DOM when vote is made
  const immediatelyVoteUpdate = (type: VoteType | null, currentVote?: Vote) => {
    if (!user) return;
    let up: any = upvotes;
    let down: any = downvotes;
    if (currentVote) {
      if (type === VoteType.Downvote) {
        up = upvotes.filter((upvote) => upvote.id !== currentVote.id);
        down =
          currentVote.type === type
            ? downvotes.filter((downvote) => downvote.id !== currentVote.id)
            : [...down, { ...currentVote, type }];
      } else if (type === VoteType.Upvote) {
        down = downvotes.filter((downvote) => downvote.id !== currentVote.id);
        up =
          currentVote.type === type
            ? upvotes.filter((upvote) => upvote.id !== currentVote.id)
            : [...up, { ...currentVote, type }];
      }
    } else {
      if (type === VoteType.Upvote) {
        up.push({ created_by_id: user.id } as any);
      } else {
        down.push({ created_by_id: user.id } as any);
      }
    }
    setUpvotes(up);
    setDownvotes(down);
  };

  return (
    <div className="flex my-4 mx-4 items-center justify-between h-16 relative">
      {request.played && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-500/40"></div>
      )}
      <div className="flex items-center" onClick={() => handlePlayed()}>
        <div className="h-16 w-16 mr-4">
          <img src={request.track.image} alt="album" />
        </div>
        <div>
          <p className="line-clamp-1">{request.track.title}</p>
          <p className="text-stone-500 line-clamp-1">{request.track.artists}</p>
        </div>
      </div>
      <div className="flex h-full items-center">
        <PlayIcon
          className={classNames('h-6 w-6', {
            'color-primary': userUpvoted,
          })}
          onClick={() => !voting && handleVote(VoteType.Upvote)}
        />

        <div className="min-w-[48px] flex justify-center">
          <span>{upvotes.length - downvotes.length}</span>
        </div>
        <StopIcon
          className={classNames('h-6 w-6', {
            'color-primary': userDownvoted,
          })}
          onClick={() => !voting && handleVote(VoteType.Downvote)}
        />
      </div>
    </div>
  );
};

export default SongRequestListItem;
