import { PlaceAddress } from '../@types';
import { omit } from 'lodash';
export const transformGooglePlace = (place: any): Partial<PlaceAddress> => {
  return {
    formattedAddress: place.formatted_address,
    name: place.name,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
    googlePlaceId: place.place_id,
    rawGooglePlace: JSON.stringify(omit(place, ['geometry'])),
  };
};
