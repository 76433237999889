import * as Actions from '../store/auth-state';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { supabase } from '../../@utils/supabase/supabase-client';
import {
  AuthChangeEvent,
  Session,
  User as SupabaseUser,
} from '@supabase/supabase-js';

import { definitions } from '../../@types';
import { camelize } from '../../@utils';

export const useAuth = () => {
  const dispatch = useDispatch();
  const login = async (
    email: string,
    password: string,
  ): Promise<void | any> => {
    try {
      const { user } = await supabase.auth.signIn({ email, password });
      if (user) {
        const appUser = await getUser(user.id);
        dispatch(Actions.setUser(appUser));
      }
      return user;
    } catch (error) {
      throw error;
    }
  };

  const register = async (
    email: string,
    password: string,
    username: string,
    subscribe: boolean,
  ): Promise<void | any> => {
    try {
      const { error } = await supabase.auth.signUp(
        {
          email,
          password,
        },
        {
          data: {
            username,
          },
        },
      );
      if (error) throw error;
      if (subscribe) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/mailchimp/subscribe`,
          {
            method: 'POST',
            body: JSON.stringify({ user: { email, username } }),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const sendResetPasswordEmail = (email: string): Promise<any> => {
    return supabase.auth.api.resetPasswordForEmail(email);
  };

  const signOut = async (): Promise<void> => {
    try {
      await supabase.auth.signOut();
      dispatch(Actions.setUser(null));
    } catch (error) {
      throw error;
    }
  };

  const authUser = (): SupabaseUser | null => {
    return supabase.auth.user();
  };

  const createUser = async (username: string, authUser: SupabaseUser) => {
    try {
      const { data, error } = await supabase
        .from<definitions['users']>('users')
        .insert([
          {
            username,
            email: authUser.email,
            role: 'user',
            id: authUser.id,
          },
        ]);
      if (error) throw error;
      return data;
    } catch (error) {
      throw error;
    }
  };

  const getUser = async (authId: string) => {
    try {
      const { data, error } = await supabase
        .from<definitions['users']>('users')
        .select()
        .eq('id', authId);
      if (error) throw error;
      return data ? data.map((user) => camelize(user))[0] : null;
    } catch (error) {
      throw error;
    }
  };

  const getSignedInUser = async () => {
    try {
      const auth = authUser();
      if (auth) {
        return getUser(auth.id);
      }
    } catch (error) {
      throw error;
    }
  };

  const checkEmailAvailability = async (email: string): Promise<boolean> => {
    try {
      const { data, error } = await supabase
        .from<definitions['users']>('users')
        .select()
        .eq('email', email);
      if (error) throw error;
      return data && data.length === 0 ? true : false;
    } catch (error) {
      throw error;
    }
  };

  const checkUsernameAvailability = async (
    username: string,
  ): Promise<boolean> => {
    try {
      const { data, error } = await supabase
        .from<definitions['users']>('users')
        .select()
        .eq('username', username);
      if (error) throw error;
      return data && data.length === 0 ? true : false;
    } catch (error) {
      throw error;
    }
  };

  const updateAuth = async (value: { email?: string; password?: string }) => {
    try {
      const { user, error } = await supabase.auth.update(value);
      if (error) throw error;
      return user;
    } catch (error) {
      throw error;
    }
  };

  const updateUser = async (
    id: string,
    user: Partial<definitions['users']>,
  ) => {
    try {
      const { data, error } = await supabase
        .from<definitions['users']>('users')
        .update(user)
        .match({ id })
        .single();
      if (error) throw error;
      return data ? camelize(data) : null;
    } catch (error) {
      throw error;
    }
  };

  return {
    login,
    register,
    sendResetPasswordEmail,
    authUser,
    createUser,
    getUser,
    getSignedInUser,
    signOut,
    checkEmailAvailability,
    checkUsernameAvailability,
    updateAuth,
    updateUser,
  };
};

export const useAuthState = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange(
      async (event: AuthChangeEvent, session: Session | null) => {
        if (session && session.user) {
          const user = await auth.getUser(session.user.id);
          dispatch(Actions.setUser(user));
        } else {
          dispatch(Actions.setUser(null));
        }
      },
    );
    if (data) {
      return data.unsubscribe;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
