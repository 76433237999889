import { createSlice } from '@reduxjs/toolkit';
import { CallState, DjEvent, LoadingState } from '../../@types';

export interface EventsState {
  events: DjEvent[];
  currentEvent: DjEvent | null;
  loadingEvents: CallState;
  currentEventLoading: CallState;
}

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    currentEvent: null,
    events: [],
    loadingEvents: LoadingState.INIT,
    currentEventLoading: LoadingState.INIT,
  } as EventsState,
  reducers: {
    getEvents: (state) => {
      state.loadingEvents = LoadingState.LOADING;
    },
    getEventsSuccess: (state, action) => {
      state.loadingEvents = LoadingState.LOADED;
      state.events = action.payload;
    },
    getEventsFailure: (state, action) => {
      state.loadingEvents = { error: action.payload };
    },
    getCurrentEvent: (state) => {
      state.currentEventLoading = LoadingState.LOADING;
    },
    getCurrentEventSuccess: (state, action) => {
      state.currentEventLoading = LoadingState.LOADED;
      state.currentEvent = action.payload;
    },
    getCurrentEventFailure: (state, action) => {
      state.currentEventLoading = { error: action.payload };
    },
  },
});

export const {
  getEvents,
  getEventsSuccess,
  getEventsFailure,
  getCurrentEvent,
  getCurrentEventSuccess,
  getCurrentEventFailure,
} = eventsSlice.actions;

export default eventsSlice.reducer;
