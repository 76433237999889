import { format } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { DjEvent } from '../../../../@types';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { fadeContainer } from '../../../../shared/helpers/animations';
export const EventListItem: React.FC<{
  handleClick: (event: DjEvent) => void;
  event: DjEvent;
}> = ({ handleClick, event }) => {
  console.log('event', event);
  const [clicked, setClicked] = useState(false);
  const formatDate = (start: Date, end: Date) => {
    const startTime = format(start, 'p');
    const endTime = format(end, 'p');
    return ` ${startTime} - ${endTime}`;
  };

  return (
    <motion.div
      className="bg-zinc-900 rounded-lg"
      variants={fadeContainer}
      onClick={() => {
        setTimeout(() => handleClick(event), 100);
        setClicked(true);
      }}
    >
      <div className={`p-2 flex items-center ${clicked && 'bg-gray-600'}`}>
        <div className={`flex flex-col w-full p-2`}>
          <div className="flex align-center w-full pb-4">
            <h1 className="text-3xl">{event.name}</h1>
          </div>
          <div className="flex justify-between">
            <span>{format(parseISO(event.startDate), 'eeee, MMMM d')}</span>
            <span>
              {formatDate(parseISO(event.startDate), parseISO(event.endDate))}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-400">{event.placeAddress?.name}</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
