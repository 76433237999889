import { debounce } from 'lodash';
import { useAuth } from '../../auth/services/auth.service';
import { emailValidator } from '../../@utils/validators/form-validators';

export const useValidators = () => {
  const auth = useAuth();
  const validateEmail = async (email: string): Promise<string | boolean> => {
    const isValid = emailValidator(email);
    if (!isValid) {
      return 'Valid email is required';
    }
    return new Promise((resolve, reject) => {
      debounce(async (value) => {
        const emailAvailable = await auth.checkEmailAvailability(value);
        resolve(emailAvailable || 'Email is not available');
      }, 500)(email);
    });
  };

  const validateUsername = async (
    username: string,
  ): Promise<string | boolean> => {
    return new Promise(async (resolve, reject) => {
      const isAvailable = await auth.checkUsernameAvailability(username);
      resolve(isAvailable || 'Username is not available');
    });
  };

  return {
    validateEmail,
    validateUsername,
  };
};
