import { createClient, SupabaseClient } from '@supabase/supabase-js';

const supabaseUrl = process.env?.REACT_APP_SUPABASE_URL as string;
const supabaseAnonKey = process.env?.REACT_APP_SUPABASE_ANON_KEY as string;
let db;
if (process.env) {
  db = createClient(supabaseUrl, supabaseAnonKey, {
    autoRefreshToken: true,
    persistSession: true,
  });
} else {
  setTimeout(
    () =>
      (db = createClient(supabaseUrl, supabaseAnonKey, {
        autoRefreshToken: true,
        persistSession: true,
      })),
    1000,
  );
}

export const supabase = db as SupabaseClient;
