import { useParams } from 'react-router-dom';
import {
  useGetSongRequestsForEventListener,
  useSongRequest,
} from '../../shared/services/song-request.service';
import RequestModal from './components/request-modal';
import SongRequestListItem from './components/song-request-list-item';
import { useSelector } from 'react-redux';
import { RootState, useAppSelector } from '../../store';
import AuthModal from '../../auth/components/auth-modal';
import { useEffect, useRef, useState } from 'react';
import {
  DjEvent,
  LoadingState,
  SongRequest,
  SpotifyTrack,
  User,
  VoteType,
} from '../../@types';
import TitleBar from '../../shared/components/title-bar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useEvent } from '../../shared/services/event.service';
import { format, isAfter, isBefore, subHours } from 'date-fns';
import { Toast } from 'primereact/toast';
import { InformationCircleIcon } from '@heroicons/react/solid';
import ViewEventModal from '../events/components/view-event-modal';
import Heading from '../../shared/components/heading';
import { motion } from 'framer-motion';
import { fadeContainer, fadeIn } from '../../shared/helpers/animations';
import Loading from '../../shared/components/Loading';

const Requests: React.FC = () => {
  // params
  const params = useParams<{ id: string }>();

  // services and hooks
  const songRequest = useSongRequest();
  const eventService = useEvent();
  useGetSongRequestsForEventListener(params.id as string);

  // modals
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [showViewEventModal, setShowViewEventModal] = useState<boolean>(false);

  // local state
  const [requests, setRequests] = useState<SongRequest[]>([]);
  const [played, setPlayed] = useState<SongRequest[]>([]);

  // refs
  const toast = useRef<any>(null);

  // redux state
  const songRequests: SongRequest[] = useAppSelector(
    (state) => state.djEvent.requests,
  );
  const user = useAppSelector((state) => state.auth.user) as User | undefined;
  const { currentEvent, currentEventLoading } = useAppSelector(
    (state) => state.events,
  );

  // effects
  useEffect(() => {
    if (params.id) {
      eventService.getEvent(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    const allRequests = filterRequests(songRequests);
    setRequests(allRequests.requests);
    setPlayed(allRequests.played);
  }, [JSON.stringify(songRequests)]);

  // methods
  const filterRequests = (allRequests: SongRequest[]) => {
    return allRequests.reduce(
      (acc, el) => {
        if (el.played) {
          acc.played.push(el);
        } else {
          acc.requests.push(el);
        }
        return acc;
      },
      {
        requests: [] as SongRequest[],
        played: [] as SongRequest[],
      },
    );
  };

  const handleDismiss = (track: SpotifyTrack | null) => {
    if (track && user && currentEvent) {
      // needed to add song immediately
      const pseudoRequest: SongRequest = {
        id: 0,
        track: {
          id: 0,
          spotifyId: track.id,
          album: track.album || '',
          artists: track.artists,
          title: track.label,
          rawSpotifyTrack: JSON.stringify(track),
          image: track.image ? track.image : '',
        },
        votes: [
          {
            created_by_id: user.id,
            type: VoteType.Upvote,
          } as any,
        ],
        trackId: 0,
        requestedById: user.id,
        djEventId: currentEvent.id,
        createdDate: new Date().toISOString(),
        createdById: user.id,
        played: false,
      };
      setRequests([...requests, pseudoRequest]);
      songRequest.createSongRequest({ track, eventId: params.id as string });
    }
    setShowRequestModal(false);
  };

  const handlePresent = () => {
    if (!user) {
      setShowAuthModal(true);
    } else {
      if (canRequest()) {
        setShowRequestModal(true);
      } else {
        showError();
      }
    }
  };

  const handleEventModalDismiss = () => {
    setShowViewEventModal(false);
    if (params.id) {
      eventService.getEvent(params.id);
    }
  };

  const canRequest = () => {
    if (currentEvent) {
      if (
        isAfter(new Date(), subHours(new Date(currentEvent?.startDate), 1)) &&
        isBefore(new Date(), new Date(currentEvent?.endDate))
      ) {
        return true;
      }
    }
    return false;
  };

  const showError = (title?: string, message?: string) => {
    if (toast?.current) {
      toast.current.show({
        severity: 'error',
        summary: title || 'Unable to make requests yet',
        detail:
          message ||
          `You can start making requests at ${
            currentEvent &&
            format(subHours(new Date(currentEvent?.startDate), 1), 'p')
          }.`,
        life: 5000,
      });
    }
  };

  return (
    <div className="">
      <TitleBar
        title={currentEvent?.name || ''}
        showBack={true}
        rightButton={
          <InformationCircleIcon
            className="h-6 w-6"
            onClick={() =>
              user
                ? currentEvent && setShowViewEventModal(true)
                : handlePresent()
            }
          />
        }
      ></TitleBar>
      <div className="flex justify-center w-full h-full">
        <div className="max-w-screen-md w-full h-full">
          {currentEventLoading === LoadingState.LOADED ? (
            <>
              <motion.div
                className="p-4"
                variants={fadeIn}
                initial="hidden"
                animate="visible"
              >
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-search" />
                  <InputText
                    className="w-full"
                    readOnly
                    onChange={() => handlePresent()}
                    onClick={() => handlePresent()}
                    placeholder="Make a request"
                  />
                </span>
              </motion.div>
              <motion.div
                variants={fadeContainer}
                initial="hidden"
                animate="visible"
              >
                {requests &&
                  requests.length > 0 &&
                  requests.map((request) => (
                    <SongRequestListItem
                      key={request.id}
                      request={request}
                      user={user}
                      canRequest={canRequest}
                      showError={showError}
                      setShowAuthModal={setShowAuthModal}
                    />
                  ))}
              </motion.div>
              {played && played.length > 0 && (
                <motion.div
                  variants={fadeContainer}
                  initial="hidden"
                  animate="visible"
                >
                  <div className="pt-6 pb-2 pl-4">
                    <Heading label="Played Songs" />
                  </div>
                  {played.map((request) => (
                    <SongRequestListItem
                      key={request.id}
                      request={request}
                      user={user}
                      canRequest={canRequest}
                      showError={showError}
                      setShowAuthModal={setShowAuthModal}
                    />
                  ))}
                </motion.div>
              )}
            </>
          ) : (
            <div className="h-screen w-screen">
              <Loading />
            </div>
          )}
        </div>
      </div>
      <Dialog
        visible={showAuthModal}
        onHide={() => {}}
        showHeader={false}
        contentClassName="p-0"
        contentStyle={{ padding: 0 }}
      >
        <AuthModal onDismiss={setShowAuthModal} showModal={showAuthModal} />
      </Dialog>
      <Dialog
        visible={showRequestModal}
        onHide={() => {}}
        showHeader={false}
        contentClassName="p-0 h-full w-screen max-h-full"
        className="h-full max-h-full"
        contentStyle={{ padding: 0 }}
      >
        <RequestModal
          onDismiss={(track: SpotifyTrack | null) => handleDismiss(track)}
        />
      </Dialog>
      <Toast ref={toast} position="top-center" />
      {currentEvent && (
        <Dialog
          visible={showViewEventModal}
          onHide={() => setShowViewEventModal(false)}
          style={{ width: '600px' }}
        >
          <ViewEventModal
            onDismiss={() => handleEventModalDismiss()}
            djEvent={currentEvent}
          />
        </Dialog>
      )}
    </div>
  );
};

export default Requests;
