import { useEffect, useState } from 'react';
import CreateEventModal from './components/create-event-modal';
import { useEvent } from '../../shared/services/event.service';
import { EventListItem } from './components/event-list-item';
import { useNavigate } from 'react-router-dom';
import TitleBar from '../../shared/components/title-bar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DjEvent, LoadingState, UserRoles } from '../../@types';
import { isAfter, isToday } from 'date-fns';
import Heading from '../../shared/components/heading';
import { UserCircleIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { useAppSelector } from '../../store';
import Loading from '../../shared/components/Loading';

const Events: React.FC = () => {
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [djEvents, setDjEvents] = useState<{
    today: DjEvent[];
    upcoming: DjEvent[];
  }>({ today: [], upcoming: [] });
  const { user } = useAppSelector((state) => state.auth);
  const { events, loadingEvents } = useAppSelector((state) => state.events);
  const { getEvents } = useEvent();
  const navigate = useNavigate();

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingEvents !== LoadingState.LOADED) return;
    handleEvents();
  }, [loadingEvents]);

  const handleEvents = () => {
    const sortedEvents = events.reduce(
      (
        acc: {
          today: DjEvent[];
          upcoming: DjEvent[];
        },
        el: DjEvent,
      ) => {
        const today = new Date();
        if (isToday(new Date(el.startDate)) || isToday(new Date(el.endDate))) {
          acc.today.push(el);
        } else if (isAfter(new Date(el.startDate), today)) {
          acc.upcoming.push(el);
        }
        return acc;
      },
      {
        today: [],
        upcoming: [],
      },
    );
    setDjEvents(sortedEvents);
  };

  return (
    <div className="">
      <TitleBar
        title="Events"
        showBack={false}
        rightButton={
          <UserCircleIcon
            className="h-6 w-6"
            onClick={() => navigate('/profile')}
          />
        }
      />

      <div className="flex justify-center w-full h-full">
        <div className="max-w-screen-sm w-full h-full">
          {loadingEvents === LoadingState.LOADED ? (
            <motion.div className="p-4">
              <div className="pt-6">
                <div>
                  <Heading label="Today" />
                </div>
                {djEvents.today.length > 0 ? (
                  djEvents.today.map((djEvent, index) => (
                    <EventListItem
                      key={djEvent.id}
                      event={djEvent}
                      handleClick={(djEvent) =>
                        navigate(`/requests/${djEvent.id}`)
                      }
                    ></EventListItem>
                  ))
                ) : (
                  <div className="p-4 text-center">No events today.</div>
                )}
              </div>
              <div className="pt-6">
                <div>
                  <Heading label="Upcoming Events" />
                </div>
                {djEvents.upcoming.length > 0 ? (
                  djEvents.upcoming.map((djEvent, index) => (
                    <EventListItem
                      key={djEvent.id}
                      event={djEvent}
                      handleClick={(djEvent) =>
                        navigate(`/requests/${djEvent.id}`)
                      }
                    ></EventListItem>
                  ))
                ) : (
                  <div className="p-4 text-center">No upcoming events.</div>
                )}
              </div>
            </motion.div>
          ) : (
            <div className="h-screen w-screen">
              <Loading />
            </div>
          )}
        </div>
      </div>
      {user && user.role === UserRoles.DJ && (
        <>
          <div className="absolute bottom-4 right-4">
            <Button
              icon="pi pi-plus"
              className="p-button-rounded"
              onClick={() => setShowAddEvent(true)}
            />
          </div>
          <Dialog
            header="Add Event"
            visible={showAddEvent}
            onHide={() => setShowAddEvent(false)}
            style={{ width: '600px' }}
          >
            <CreateEventModal
              onDismiss={() => {
                setShowAddEvent(false);
                getEvents();
              }}
              user={user}
            />
          </Dialog>
        </>
      )}
    </div>
  );
};

export default Events;
