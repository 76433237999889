import { isPlainObject, camelCase, snakeCase } from 'lodash';
/**
 * Working with Supabase we don't get a great way to map from how things
 * are defined in the table and how they are consumed in the app.  These
 * utility functions can be used to convert an object we get from the database
 * from snake case to camel case.  Also, if needed, we can convert things back
 * as well.
 *
 * Possible "gotchas" with this workflow
 * - not handling undefined
 * - not handling nested arrays
 *
 * These methods are a WIP, and can be altered as needed to fit the user's case.
 */

/**
 * Converts an objects keys to camel case keys
 * @param obj
 */
export const camelize = (obj: any): any => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = camelCase(key);
    // if it's an object, then we need to call function recursively
    const newValue = isPlainObject(value) ? camelize(value) : value;
    return {
      ...acc,
      [newKey]: newValue,
    };
  }, {});
};

/**
 * Converts an objects keys to snake case keys
 * @param obj
 */
export const snakeize = (obj: any): any => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = snakeCase(key);
    // if it's an object, then we need to call function recursively
    const newValue = isPlainObject(value) ? snakeize(value) : value;
    return {
      ...acc,
      [newKey]: newValue,
    };
  }, {});
};
