import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../@types';

export interface AuthState {
  user: User | null;
}
export const authState = createSlice({
  name: 'auth',
  initialState: {
    user: null,
  } as AuthState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = authState.actions;

export default authState.reducer;
