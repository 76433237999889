import TitleBar from '../../../shared/components/title-bar';

const AuthPageWrapper: React.FC<{
  navigate?: () => void;
  onDismiss: () => void;
  heading: string;
  title: string;
}> = ({ navigate, onDismiss, heading, title, children }) => {
  return (
    <div className="h-full w-full">
      <TitleBar
        title={title}
        rightButton={
          <div
            className="mr-4 w-16 flex justify-end cursor-pointer"
            onClick={() => onDismiss()}
          >
            <i className="pi pi-times text-xl" />
          </div>
        }
        showBack={!!navigate}
        closeModal={navigate && (() => navigate())}
      />
      <div className="flex justify-center w-full h-full">
        <div className="max-w-screen-md p-4">
          <div className="pt-6 pb-10">
            <h1 className="text-xl text-center">{heading}</h1>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthPageWrapper;
