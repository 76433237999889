import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AuthModal from '../../auth/components/auth-modal';
import { useAuth } from '../../auth/services/auth.service';
import TitleBar from '../../shared/components/title-bar';
import { RootState } from '../../store';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { User } from '../../@types';
import { InputText } from 'primereact/inputtext';
import MockInput from './components/mock-input';
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import Heading from '../../shared/components/heading';

const Profile: React.FC = () => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [editUsername, setEditUsername] = useState(false);
  const [username, setUsername] = useState('');
  const [editPassword, setEditPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useSelector<RootState>(
    (state) => state.auth.user,
  ) as User | null;

  const auth = useAuth();

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setUsername(user.username);
    }
  }, [user]);

  const saveEmail = async (email: string) => {
    try {
      if (email !== user?.email) {
        setLoading(true);
        const isUnique = await auth.checkEmailAvailability(email);
        if (isUnique && user) {
          await auth.updateAuth({ email });
          await auth.updateUser(user.id, { email });
          setEditEmail(false);
        } else {
          confirmDialog({
            message: 'Email must be unique',
            header: 'Error Changing Email',
            accept: () => null,
            reject: () => {
              setEditEmail(false);
              setEmail(user?.email || '');
            },
            acceptLabel: 'Ok',
            rejectLabel: 'Cancel',
          });
        }
      } else {
        setEditEmail(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const savePassword = async (password: string) => {
    try {
      setLoading(true);
      if (password.length < 6) {
        confirmDialog({
          message: 'Password must be at least 6 characters',
          header: 'Error Changing Password',
          accept: () => null,
          reject: () => {
            setEditPassword(false);
            setPassword('');
          },
          acceptLabel: 'Ok',
          rejectLabel: 'Cancel',
        });
      } else {
        await auth.updateAuth({ password });
        setEditPassword(false);
        setPassword('');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveUsername = async (username: string) => {
    try {
      if (username !== user?.username) {
        setLoading(true);
        const isUnique = await auth.checkUsernameAvailability(username);
        if (isUnique && user) {
          await auth.updateUser(user.id, { username });
          setEditUsername(false);
        } else {
          confirmDialog({
            message: 'username must be unique',
            header: 'Error Changing username',
            accept: () => null,
            reject: () => {
              setEditUsername(false);
              setUsername(user?.username || '');
            },
            acceptLabel: 'Ok',
            rejectLabel: 'Cancel',
          });
        }
      } else {
        setEditUsername(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen w-screen">
      <TitleBar title="Profile" />
      <div className="flex justify-center w-full h-full">
        <div className="max-w-screen-md w-full">
          {user ? (
            <div className="w-full p-4">
              <div className="pt-6 pb-4">
                <Heading label="User Info" />
              </div>
              <div className="mb-4">
                {editEmail ? (
                  <span className="p-input-icon-right w-full border-transparent p-float-label mb-2">
                    <i
                      className={
                        loading ? 'pi pi-spin pi-spinner' : 'pi pi-save'
                      }
                      onClick={() => saveEmail(email)}
                    />
                    <InputText
                      autoFocus={true}
                      id="email"
                      className="w-full bg-transparent border-transparent"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={loading}
                    />
                    <label htmlFor="email">Email</label>
                  </span>
                ) : (
                  <MockInput
                    handleEdit={(editing) => setEditEmail(editing)}
                    value={user.email}
                    label="Email"
                  />
                )}
              </div>
              <div className="mb-4">
                {editUsername ? (
                  <span className="p-input-icon-right w-full border-transparent p-float-label mb-2">
                    <i
                      className={
                        loading ? 'pi pi-spin pi-spinner' : 'pi pi-save'
                      }
                      onClick={() => saveUsername(username)}
                    />
                    <InputText
                      autoFocus={true}
                      id="username"
                      className="w-full bg-transparent border-transparent"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      disabled={loading}
                    />
                    <label htmlFor="email">Username</label>
                  </span>
                ) : (
                  <MockInput
                    handleEdit={(editing) => setEditUsername(editing)}
                    value={user.username}
                    label="Username"
                  />
                )}
              </div>
              <div className="mb-4">
                {editPassword ? (
                  <span className="p-input-icon-right w-full border-transparent p-float-label mb-2">
                    <i
                      className={
                        loading ? 'pi pi-spin pi-spinner' : 'pi pi-save'
                      }
                      onClick={() => savePassword(password)}
                    />
                    <InputText
                      autoFocus={true}
                      id="password"
                      className="w-full bg-transparent border-transparent"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      disabled={loading}
                    />
                    <label htmlFor="email">Password</label>
                  </span>
                ) : (
                  <MockInput
                    handleEdit={(editing) => setEditPassword(editing)}
                    value="********"
                    label="Password"
                  />
                )}
              </div>
              <div className="pb-4">
                <Heading label="Settings" />
              </div>
              <div className="flex justify-center">
                <Button onClick={() => auth.signOut()}>Logout</Button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center h-full w-full">
              <div className="pb-12">
                <h1 className="text-xl">Login to access your account</h1>
              </div>
              <div className="mb-12">
                <Button
                  label="Login"
                  className="p-button-raised"
                  onClick={() => setShowAuthModal(true)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        visible={showAuthModal}
        onHide={() => {}}
        showHeader={false}
        contentClassName="p-0"
        contentStyle={{ padding: 0 }}
      >
        <AuthModal onDismiss={setShowAuthModal} showModal={showAuthModal} />
      </Dialog>
    </div>
  );
};

export default Profile;
