import { ArtistObjectSimplified, TrackObjectFull } from './spotify-namespace';

export class SpotifyTrack {
  album: string | null;
  artists: string;
  label: string;
  id: string;
  original: TrackObjectFull;
  image?: string;
  constructor(track: SpotifyTrack | TrackObjectFull) {
    if (track instanceof SpotifyTrack) {
      this.id = track.id;
      this.label = track.label;
      this.album = track.album;
      this.artists = track.artists;
      this.original = track.original;
      this.image = track.image;
    } else {
      this.id = track.id;
      this.label = track.name;
      this.original = track;
      this.artists = this.formatArtists(track.artists);
      this.album = track.album?.name || null;
      if (track.album.images) {
        this.image = track.album.images[0].url;
      }
    }
  }

  formatArtists(artists: ArtistObjectSimplified[]): string {
    return artists.map((a) => a.name).join(', ');
  }
}
