import { createSlice } from '@reduxjs/toolkit';
import {
  CallState,
  DjEvent,
  LoadingState,
  SongRequest,
  VoteType,
} from '../../@types';

export interface DjEventState {
  currentEvent: DjEvent | null;
  requests: SongRequest[] | [];
  singleRequestLoading: CallState;
  allRequestsLoading: CallState;
}
export const djEventState = createSlice({
  name: 'djEvent',
  initialState: {
    currentEvent: null,
    requests: [],
    singleRequestLoading: LoadingState.INIT,
    allRequestsLoading: LoadingState.INIT,
  } as DjEventState,
  reducers: {
    getSongRequests: (state, action) => {
      state.allRequestsLoading = LoadingState.LOADING;
    },
    getSongRequestsSuccess: (state, action) => {
      state.allRequestsLoading = LoadingState.LOADED;
      state.requests = action.payload;
    },
    getSongRequestsFailure: (state, action) => {
      state.allRequestsLoading = { error: action.payload };
    },
    getSingleRequest: (state, action) => {
      state.singleRequestLoading = LoadingState.LOADING;
    },
    getSingleRequestSuccess: (state, action) => {
      let found = false;
      state.singleRequestLoading = LoadingState.LOADED;
      const requests = state.requests
        .map((request) => {
          if (request.id === action.payload.id) {
            found = true;
            return action.payload;
          }
          return request;
        })
        .sort((a: SongRequest, b: SongRequest) => {
          const aUpvotes = a.votes.filter(
            (vote) => vote.type === VoteType.Upvote,
          );
          const aDownvotes = a.votes.filter(
            (vote) => vote.type === VoteType.Downvote,
          );
          const bUpvotes = b.votes.filter(
            (vote) => vote.type === VoteType.Upvote,
          );
          const bDownvotes = b.votes.filter(
            (vote) => vote.type === VoteType.Downvote,
          );
          const aVotes = aUpvotes.length - aDownvotes.length;
          const bVotes = bUpvotes.length - bDownvotes.length;
          if (aVotes > bVotes) {
            return -1;
          }
          if (aVotes < bVotes) {
            return 1;
          }
          return 0;
        });
      state.requests = found ? requests : [...state.requests, action.payload];
    },
    getSingleRequestFailure: (state, action) => {
      state.singleRequestLoading = { error: action.payload };
    },
  },
});

export const {
  getSongRequests,
  getSongRequestsFailure,
  getSongRequestsSuccess,
  getSingleRequest,
  getSingleRequestSuccess,
  getSingleRequestFailure,
} = djEventState.actions;

export default djEventState.reducer;
