import { DjEvent, PlaceAddress, definitions } from '../../@types';
import { camelize } from '../../@utils';
import { supabase } from '../../@utils/supabase/supabase-client';
import { useAppDispatch } from '../../store';
import * as eventsActions from '../store/events-state';
interface CreateEventValues {
  place: Partial<PlaceAddress>;
  start: Date;
  end: Date;
  user: any;
  name: string;
}

export const useEvent = () => {
  const dispatch = useAppDispatch();
  const createEvent = async ({
    place,
    start,
    end,
    user,
    name,
  }: CreateEventValues) => {
    try {
      // create place
      const placeAddress = await upsertPlace(place);
      const { data, error } = await supabase
        .from<definitions['dj_event']>('dj_event')
        .insert([
          {
            place_id: placeAddress.id,
            start_date: start.toISOString(),
            end_date: end.toISOString(),
            created_by_id: user.id,
            name,
          },
        ]);
      if (error) throw error;
      return data;
    } catch (error) {
      throw error;
    }
  };

  const updateEvent = async (id: string | number, djEvent: DjEvent) => {
    try {
      const placeId = djEvent.placeAddress
        ? djEvent.placeAddress?.id ||
          (await upsertPlace(djEvent.placeAddress)).id
        : null;
      if (!placeId) throw new Error('Unable to find place');
      const updatedEvent = {
        place_id: placeId,
        name: djEvent.name,
        start_date: new Date(djEvent.startDate).toISOString(),
        end_date: new Date(djEvent.endDate).toISOString(),
      };
      const { data, error } = await supabase
        .from<definitions['dj_event']>('dj_event')
        .update(updatedEvent)
        .match({ id })
        .single();
      if (error) throw error;
      return data ? camelize(data) : null;
    } catch (error) {
      throw error;
    }
  };

  const getEvents = async () => {
    try {
      dispatch(eventsActions.getEvents());
      const { data, error } = await supabase
        .from<
          definitions['dj_event'] & {
            place_address: definitions['place_address'];
          }
        >('dj_event')
        .select('*, place_address (*)');
      if (error) throw error;

      const events = data?.map((d) => camelize(d)) || [];
      dispatch(eventsActions.getEventsSuccess(events));
    } catch (error) {
      console.error('error in get', error);
      dispatch(eventsActions.getEventsFailure(error));
      throw error;
    }
  };

  const getEvent = async (id: string | number): Promise<void> => {
    try {
      dispatch(eventsActions.getCurrentEvent());
      const { data, error } = await supabase
        .from<definitions['dj_event']>('dj_event')
        .select('*, place_address (*)')
        .eq('id', id)
        .single();
      if (error) throw error;
      const event = data ? camelize(data) : null;
      dispatch(eventsActions.getCurrentEventSuccess(event));
    } catch (error) {
      dispatch(eventsActions.getCurrentEventFailure(error));
      throw error;
    }
  };

  const upsertPlace = async (place: Partial<PlaceAddress>) => {
    try {
      const { data, error } = await supabase
        .from<definitions['place_address']>('place_address')
        .upsert(
          {
            formatted_address: place.formattedAddress,
            name: place.name,
            lat: parseInt(place.lat as any),
            lng: parseInt(place.lng as any),
            google_place_id: place.googlePlaceId,
            raw_google_place: place.rawGooglePlace,
          },
          { onConflict: 'google_place_id' },
        )
        .single();
      if (error) throw error;
      return data ? camelize(data) : null;
    } catch (error) {
      throw error;
    }
  };

  return {
    createEvent,
    getEvents,
    getEvent,
    updateEvent,
  };
};
