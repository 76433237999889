import { useEffect, useState } from 'react';
import { useSpotify } from '../../../../shared/services/spotify.service';
import { debounce } from 'lodash';
import { SpotifyTrack } from '../../../../@types';
import TitleBar from '../../../../shared/components/title-bar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const RequestModal: React.FC<{
  onDismiss: (track: SpotifyTrack | null) => void;
}> = ({ onDismiss }) => {
  const spotify = useSpotify();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (!searchText || searchText.length === 0) {
      setSearchResults([]);
    }
    if (searchText && searchText.length > 0) {
      setLoading(true);
    }
    searchTrack(searchText);
    return () => {
      searchTrack.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const searchTrack = debounce(async (trackQuery) => {
    try {
      if (!trackQuery || trackQuery.length <= 1) {
        setSearchResults([]);
        return;
      }
      const tracks = await spotify.searchSpotify(trackQuery as string);
      setSearchResults(tracks);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, 750);

  return (
    <div className="h-full w-full">
      <TitleBar
        title="Request Song"
        showBack={true}
        closeModal={() => onDismiss(null)}
      ></TitleBar>
      <div className="flex justify-center w-full">
        <div className="max-w-screen-md w-full">
          <div className="p-4">
            <span className="p-input-icon-left w-full">
              {loading ? (
                <i className="pi pi-spin pi-spinner" />
              ) : (
                <i className="pi pi-search" />
              )}
              <InputText
                className="w-full"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                placeholder="Search songs"
                autoFocus
              />
              <div className="absolute h-full top-0 right-0 flex items-center">
                <Button
                  icon="pi pi-times"
                  className="p-button-rounded p-button-text"
                  onClick={() => setSearchText('')}
                />
              </div>
            </span>
          </div>

          <div className="pb-4">
            {searchResults.map((result: SpotifyTrack) => (
              <div
                className="flex my-4 mx-4 items-center justify-between"
                key={result.id}
                onClick={() => onDismiss(result)}
              >
                <div className="flex items-center">
                  <div className="h-16 w-16 mr-4">
                    <img src={result.image} alt="album" />
                  </div>
                  <div>
                    <p>{result.label}</p>
                    <p className="text-stone-500">{result.artists}</p>
                  </div>
                </div>
                <div>
                  <i className="pi pi-chevron-right color-primary"></i>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestModal;
