import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../shared/components/form-input';
import { emailValidator } from '../../../@utils/validators/form-validators';
import { useAuth } from '../../services/auth.service';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AuthPageWrapper from '../../components/auth-page-wrapper';

const Login: React.FC<{
  navigate: (path: string) => void;
  onDismiss: () => void;
}> = ({ navigate, onDismiss }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      email: '',
      // password: '',
    },
  });
  const auth = useAuth();
  const toast = useRef<Toast>(null);

  const showError = () => {
    toast.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Unable to login.  Check your info and try again',
    });
  };

  React.useEffect(() => {
    const subscription = watch(async () => {
      await trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const handleLogin = async (event: any) => {
    try {
      const user = await auth.login(event.email, event.password);
      if (!user) {
        throw new Error();
      }
      onDismiss();
    } catch (error) {
      showError();
    }
  };

  return (
    <AuthPageWrapper
      title="Login"
      heading="Login or create an account to start requesting!"
      onDismiss={() => onDismiss()}
    >
      <form onSubmit={handleSubmit((data) => handleLogin(data))}>
        <FormInput
          label="Email"
          name="email"
          control={control}
          rules={{
            validate: (value: string) => {
              return emailValidator(value) || 'Valid email is required';
            },
            required: { value: true, message: 'Email is required' },
          }}
          errors={errors}
        />
        <FormInput
          label="Password"
          name="password"
          type="password"
          control={control}
          rules={{
            required: { value: true, message: 'Password is required' },
            minLength: {
              value: 6,
              message: 'Password needs to be more than 6 characters',
            },
          }}
          errors={errors}
        />

        <div className="flex justify-center pb-2 pt-6">
          <Button
            onClick={handleSubmit((data) => handleLogin(data))}
            className="p-raised-button"
          >
            Login
          </Button>
        </div>
        <div className="flex justify-center">
          {/* <Button 
          //   className="p-button-text mr-2"
          //   onClick={() => navigate('forgot-password')}
          // >
          //   Forgot Password
          // </Button>
          */}
          <Button
            className="p-button-text"
            onClick={() => navigate('register')}
          >
            Register
          </Button>
        </div>
      </form>
      <Toast ref={toast} position="bottom-center" />
    </AuthPageWrapper>
  );
};

export default Login;
