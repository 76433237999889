import { useRef } from 'react';
import FormInput from '../../../shared/components/form-input';
import { useForm } from 'react-hook-form';
import { emailValidator } from '../../../@utils/validators/form-validators';
import React from 'react';
import { useAuth } from '../../services/auth.service';
import AuthPageWrapper from '../../components/auth-page-wrapper';
import { AuthRoutes } from '../../components/auth-modal';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

const ForgotPassword: React.FC<{
  navigate: (path: string) => void;
  onDismiss: () => void;
}> = ({ navigate, onDismiss }) => {
  const toast = useRef<any>(null);
  const errorToast = useRef<any>(null);
  const auth = useAuth();

  const showSuccess = () => {
    if (toast?.current) {
      toast.current.show({
        severity: 'success',
        summary: 'Email Sent!',
        detail: 'Check your inbox to reset your password',
        life: 5000,
      });
    }
  };

  const showError = (message: string) => {
    if (toast?.current) {
      errorToast.current.show({
        severity: 'error',
        summary: 'Error!',
        detail: message,
        life: 5000,
      });
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
  } = useForm();
  const handleForgotPassword = async (event: any) => {
    try {
      await auth.sendResetPasswordEmail(event.email);
      showSuccess();
    } catch (error: any) {
      console.error(error);
      showError(error.message || 'Unable to reset password.');
    }
  };

  React.useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      await trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  return (
    <AuthPageWrapper
      title="Login"
      navigate={() => navigate(AuthRoutes.Login)}
      onDismiss={() => onDismiss}
      heading="Enter your email to reset your password."
    >
      <form onSubmit={handleSubmit((data) => handleForgotPassword(data))}>
        <FormInput
          control={control}
          name="email"
          label="Email"
          rules={{
            validate: (value: string) =>
              emailValidator(value) || 'Valid email is required',
            required: { value: true, message: 'Email is required' },
          }}
          errors={errors}
        />
        <div className="flex justify-center">
          <Button onClick={handleSubmit((data) => handleForgotPassword(data))}>
            Reset Password
          </Button>
        </div>
      </form>
      <Toast ref={toast} position="bottom-center" />
      <Toast ref={errorToast} position="bottom-center" />
    </AuthPageWrapper>
  );
};

export default ForgotPassword;
