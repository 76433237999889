import { useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

import { useEvent } from '../../../../shared/services/event.service';
import FormInput from '../../../../shared/components/form-input';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DjEvent, PlaceAddress, User } from '../../../../@types';
import { transformGooglePlace } from '../../../../@utils';
import QRCode from 'react-qr-code';

const CreateEventModal: React.FC<{
  onDismiss: () => void;
  user: User;
  djEvent?: DjEvent;
}> = ({ onDismiss, user, djEvent }) => {
  const event = useEvent();
  const [place, setPlace] = useState<any>();
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const submit = async (data: any) => {
    if (!data.name || !data.end || !data.start || !place) {
      return;
    }
    if (djEvent) {
      await event.updateEvent(djEvent.id, {
        placeAddress: place as PlaceAddress,
        name: data.name,
        startDate: data.start,
        endDate: data.end,
      } as DjEvent);
    } else {
      await event.createEvent({
        place: place as Partial<PlaceAddress>,
        name: data.name,
        user,
        start: data.start,
        end: data.end,
      });
    }
    onDismiss();
  };

  useEffect(() => {
    if (djEvent) {
      setPlace(djEvent.placeAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      name: djEvent?.name || '',
      start: djEvent ? new Date(djEvent.startDate) : new Date(),
      end: djEvent ? new Date(djEvent.endDate) : new Date(),
    },
  });

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyD7926fX0YmrhqB5WMDWr0JufhyGBNPh_A',
    options: {
      types: ['establishment'],
      fields: [
        'formatted_address',
        'name',
        'geometry',
        'address_components',
        'place_id',
      ],
    },
    onPlaceSelected: (place) => {
      setPlace(transformGooglePlace(place));
    },
  });

  return (
    <div className="flex justify-center w-full h-full">
      <div className="p-4">
        {djEvent && (
          <div className="p-4 flex justify-center">
            <QRCode value={window.location.href} />
          </div>
        )}
        <form onSubmit={handleSubmit((data) => submit(data))}>
          <FormInput
            label="Event Name"
            name="name"
            control={control}
            errors={errors}
          />
          <div className="flex justify-between">
            <div className="p-field">
              <span className="p-float-label">
                <Controller
                  name="start"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <Calendar
                        id="start-input"
                        {...field}
                        className={classNames('w-full', {
                          'p-invalid': fieldState.invalid,
                        })}
                        showTime
                        hourFormat="12"
                      />
                    );
                  }}
                />
                <label htmlFor="start-input">Start</label>
              </span>
            </div>
            <div className="mx-4 ml-4 mb-4 h-4"></div>
            <div className="p-field">
              <span className="p-float-label">
                <Controller
                  name="end"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <Calendar
                        id="end-input"
                        {...field}
                        className={classNames('w-full', {
                          'p-invalid': fieldState.invalid,
                        })}
                        showTime
                        hourFormat="12"
                      />
                    );
                  }}
                />
                <label htmlFor="end-input">End</label>
              </span>
            </div>
          </div>
          <div className="mx-4 ml-4 mb-4 h-4"></div>

          <span className="p-float-label">
            <InputText
              className="w-100"
              ref={ref as any}
              defaultValue={djEvent?.placeAddress?.name}
              onFocus={() => setShowPlaceholder(true)}
              onBlur={() => setShowPlaceholder(false)}
              type="search"
              placeholder={showPlaceholder ? 'Enter location name' : ''}
            />

            <label htmlFor="places-input">Location</label>
          </span>
          <div className="flex w-full justify-center mt-4">
            <Button
              label="Save"
              onClick={handleSubmit((data) => submit(data))}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEventModal;
