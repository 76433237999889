import { SpotifyToken, TrackSearchResponse, SpotifyTrack } from '../../@types';
import { removeArrayDuplicates } from '../../@utils/array-helpers';

import Store from '../../store';
import { setToken } from '../store/spotify-state';

export const useSpotify = () => {
  const getSpotifyToken = async () => {
    try {
      const response = await fetch('https://spotify-token.playmysong.app', {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
      const { token } = await response.json();
      const spotifyToken = new SpotifyToken(token as any);
      Store.dispatch(setToken(spotifyToken));
      return spotifyToken;
    } catch (error) {
      throw error;
    }
  };

  const searchSpotify = async (query: string) => {
    try {
      const state = Store.getState();
      let { token } = state.spotify;
      if (!token || token?.expired) {
        token = await getSpotifyToken();
      }
      const url = `https://api.spotify.com/v1/search?q=${encodeURIComponent(
        query.trim(),
      )}&type=track`;
      const headers = { Authorization: `Bearer ${token.accessToken}` };
      const response = await fetch(url, { headers });
      if (!response.ok) {
        throw new Error(`Unable to search tracks: ${response.status}`);
      }
      const data: TrackSearchResponse = await response.json();
      const tracks: any = data.tracks.items.reduce((acc: any[], item) => {
        const track = new SpotifyTrack(item);
        if (
          track.label.toLowerCase().includes('instrumental') ||
          track.label.toLowerCase().includes('karaoke')
        ) {
          return acc;
        }
        return [...acc, track];
      }, []);
      return removeArrayDuplicates(tracks, ['label', 'artists']);
    } catch (error) {
      throw error;
    }
  };

  return {
    getSpotifyToken,
    searchSpotify,
  };
};
