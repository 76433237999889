import { format, parseISO } from 'date-fns';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { DjEvent, User, UserRoles } from '../../../../@types';
import { RootState } from '../../../../store';
import CreateEventModal from '../create-event-modal';

const ViewEventModal: React.FC<{ onDismiss: () => void; djEvent: DjEvent }> = ({
  onDismiss,
  djEvent,
}) => {
  const user = useSelector<RootState>(
    (state) => state.auth.user,
  ) as User | null;
  const [showEditEventModal, setShowEditEventModal] = useState<boolean>(false);
  const formatDate = (start: Date, end: Date) => {
    const startTime = format(start, 'p');
    const endTime = format(end, 'p');
    return `${startTime} - ${endTime}`;
  };

  const isCurrentDj = () => {
    return (
      user && user.role === UserRoles.DJ && user.id === djEvent.createdById
    );
  };
  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col items-center">
        <div className="pb-4">
          <QRCode value={window.location.href} />
        </div>
        <div>
          <h1 className="text-3xl">{djEvent.name}</h1>
        </div>
        <div className="pb-4">
          <span className="text-gray-400">
            {formatDate(parseISO(djEvent.startDate), parseISO(djEvent.endDate))}
          </span>
        </div>
        <div className="flex flex-col items-center pb-6">
          <span className="">{djEvent.placeAddress?.name}</span>
          <span className="text-gray-400">
            {djEvent.placeAddress?.formattedAddress}
          </span>
        </div>
        {isCurrentDj() && (
          <div className="pt-4">
            <Button onClick={() => setShowEditEventModal(true)}>Edit</Button>
          </div>
        )}
      </div>
      {user && isCurrentDj() && (
        <Dialog
          header="Edit Event"
          visible={showEditEventModal}
          onHide={() => setShowEditEventModal(false)}
          style={{ width: '600px' }}
        >
          <CreateEventModal
            onDismiss={() => {
              setShowEditEventModal(false);
              onDismiss();
            }}
            user={user}
            djEvent={djEvent}
          />
        </Dialog>
      )}
    </div>
  );
};

export default ViewEventModal;
