import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Auth from './auth';
import Events from './pages/events';
import Requests from './pages/requests';

// import 'primereact/resources/themes/tailwind-light/theme.css';
import 'primereact/resources/themes/arya-blue/theme.css';
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css';

/** CSS Utilities */
import './styles/utilities.css';
import './styles/typography.css';
import './styles/global.css';
import { useAuthState } from './auth/services/auth.service';
import Profile from './pages/profile';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const App: React.FC = () => {
  useAuthState();
  const location = useLocation();

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route path="/events" element={<Events />} />
          <Route path="/requests/:id" element={<Requests />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/admin" element={<Admin />}>
          <Route path="users" element={<Users />} />
        </Route> */}
          <Route path="*" element={<Navigate replace to="/events" />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;
