import { ProgressSpinner } from 'primereact/progressspinner';

const Loading: React.FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <ProgressSpinner />
    </div>
  );
};

export default Loading;
