import { SpotifyTokenPayload } from './spotify-token-payload';
import addSeconds from 'date-fns/addSeconds';
import isBefore from 'date-fns/isBefore';

export class SpotifyToken {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  scope: string;
  createdOn: Date;
  constructor(token: SpotifyTokenPayload | SpotifyToken) {
    if (token instanceof SpotifyToken) {
      this.accessToken = token.accessToken;
      this.tokenType = token.tokenType;
      this.expiresIn = token.expiresIn;
      this.scope = token.scope;
      this.createdOn = token.createdOn;
    } else {
      this.accessToken = token.access_token;
      this.tokenType = token.token_type;
      this.expiresIn = token.expires_in;
      this.scope = token.scope;
      this.createdOn = new Date();
    }
  }

  get expired(): boolean {
    const currentDate = new Date();
    const expireDate = addSeconds(this.createdOn, this.expiresIn);
    return isBefore(expireDate, currentDate);
  }
}
